import SEO from '@/components/shared/SEO'
import { useBreakpoints } from '@/hooks/useBreakpoints'
import classNames from 'classnames'
import { graphql } from 'gatsby'

const ChinhSachBaoMat = (props: any) => {
  const { data } = props

  const content = data.allWpPage.nodes[0].content

  const { isMobile } = useBreakpoints()
  const contentClass = classNames(
    'prose max-w-none tablet:border-[1px] tablet:border-gray-300 tablet:px-5 tablet:py-6 desktop:w-full',
    {
      'prose-sm': isMobile,
    }
  )

  return (
    <>
      <SEO title="Chính sách quảng cáo" />
      <div className="flex flex-col overflow-hidden">
        <div className="relative flex flex-col base-container">
          <div className="flex flex-col space-y-8 desktop:w-full">
            <h1 className="heading-2 md:heading-1">Chính sách quảng cáo</h1>
          </div>
          <div
            className={contentClass}
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  {
    allWpPage(filter: { title: { eq: "Chính sách quảng cáo" } }) {
      nodes {
        id
        title
        content
      }
    }
  }
`

export default ChinhSachBaoMat
